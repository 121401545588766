<template>

  <v-dialog v-model="dialogEnabled" persistent max-width="350">

    <v-card class="text-center">

      <v-card-title class="justify-center">
        Генерация листа персонажа
      </v-card-title>

      <v-card-text>

        <p> Давайте сгненерируем ваш лист. </p>

        <p>
           На выходе вы получите лист персонажа на русском языке в виде pdf-формы.
          Все поля можно будет впоследствии отредактировать вручную.
        </p>

        <v-progress-circular
          indeterminate
          color="pink"
          :size="50"
          v-if="loading"
        ></v-progress-circular>

        <v-btn
          v-if="!loading && !generated"
          color="pink"
          dark
          @click="generate"
        >

          <v-icon left> mdi-cog </v-icon>
           Сгенерировать лист
        </v-btn>

        <v-btn
          v-if="!loading && generated"
          color="pink"
          dark
          @click="downloadBlob()"
        >

          <v-icon left> mdi-download </v-icon>
           Скачать лист
        </v-btn>

      </v-card-text>

      <v-card-actions class="justify-end">

        <v-btn
          @click.stop="clickCancel()"
          text
          color="pink"
          :disabled="loading"
        >
          Отмена
        </v-btn>

      </v-card-actions>

    </v-card>

  </v-dialog>

</template>

<script>
import { generateSheet } from "@/api/sheets";

export default {
  name: "GenerateSheetModal",
  data: () => ({
    loading: false,
    generated: false,
    blob: null,
  }),
  props: {
    value: Boolean,
  },
  computed: {
    dialogEnabled: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async generate() {
      this.loading = true;

      const sheet_resp = await generateSheet(this.$store.getters.getCharacter);

      if (!sheet_resp.ok) {
        this.clickCancel();
        alert("Что-то пошло не так. Попробуйте еще раз.");
        return;
      }

      this.blob = await sheet_resp.blob();

      this.loading = false;
      this.generated = true;
    },
    downloadBlob() {
      const url = URL.createObjectURL(this.blob);

      let link = document.createElement("a");

      link.href = url;
      link.download = `${this.$store.getters.getInfo.characterName}.pdf`;
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    clickCancel() {
      this.dialogEnabled = false;
      this.loading = false;
      this.generated = false;
      this.blob = null;
    },
  },
};
</script>

<style scoped>

</style>
