export const damageTypes = [
    "Дробящий",
    "Колющий",
    "Рубящий",
    "Звук",
    "Излучение",
    "Кислота",
    "Некротический",
    "Огненный",
    "Психический",
    "Силовое поле",
    "Холод",
    "Электрический",
    "Яд",
]