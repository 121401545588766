export const skillList = [
    {
        name: "Acrobatics",
        displayedName: "Акробатика",
        attribute: "Dex"
    },
    {
        name: "Athletics",
        displayedName: "Атлетика",
        attribute: "Str"
    },
    {
        name: "Arcana",
        displayedName: "Магия",
        attribute: "Int"
    },
    {
        name: "Deception",
        displayedName: "Обман",
        attribute: "Cha"
    },
    {
        name: "History",
        displayedName: "История",
        attribute: "Int"
    },
    {
        name: "Insight",
        displayedName: "Проницательность",
        attribute: "Wis"
    },
    {
        name: "Intimidation",
        displayedName: "Запугивание",
        attribute: "Cha"
    },
    {
        name: "Investigation",
        displayedName: "Расследование",
        attribute: "Int"
    },
    {
        name: "Medicine",
        displayedName: "Медицина",
        attribute: "Dex"
    },
    {
        name: "Nature",
        displayedName: "Природа",
        attribute: "Wis"
    },
    {
        name: "Perception",
        displayedName: "Внимательность",
        attribute: "Wis"
    },
    {
        name: "Performance",
        displayedName: "Выступление",
        attribute: "Cha"
    },
    {
        name: "Persuasion",
        displayedName: "Убеждение",
        attribute: "Cha"
    },
    {
        name: "Religion",
        displayedName: "Религия",
        attribute: "Int"
    },
    {
        name: "SleightOfHand",
        displayedName: "Ловкость рук",
        attribute: "Dex"
    },
    {
        name: "Stealth",
        displayedName: "Скрытность",
        attribute: "Dex"
    },
    {
        name: "Survival",
        displayedName: "Выживание",
        attribute: "Wis"
    },
    {
        name: "AnimalHandling",
        displayedName: "Обращение с животными",
        attribute: "Wis"
    },
]