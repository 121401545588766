export const classes = {
  Barbarian: {
    name: "Barbarian",
    displayedName: "Варвар",
  },
  Bard: {
    name: "Bard",
    displayedName: "Бард",
  },
  Cleric: {
    name: "Cleric",
    displayedName: "Жрец",
  },
  Druid: {
    name: "Druid",
    displayedName: "Друид",
  },
  Fighter: {
    name: "Fighter",
    displayedName: "Воин",
  },
  Monk: {
    name: "Monk",
    displayedName: "Монах",
  },
  Paladin: {
    name: "Paladin",
    displayedName: "Паладин",
  },
  Ranger: {
    name: "Ranger",
    displayedName: "Следопыт",
  },
  Rogue: {
    name: "Rogue",
    displayedName: "Плут",
  },
  Sorcerer: {
    name: "Sorcerer",
    displayedName: "Чародей",
  },
  Warlock: {
    name: "Warlock",
    displayedName: "Колдун",
  },
  Wizard: {
    name: "Wizard",
    displayedName: "Волшебник",
  },
  Artificer: {
    name: "Artificer",
    displayedName: "Изобретатель",
  },
};
