<template>
  <v-app>
    <v-main class>
      <SheetCreator/>
    </v-main>
  </v-app>
</template>

<script>
import SheetCreator from "@/components/SheetCreator";
export default {
  name: 'App',

  components: {
    SheetCreator
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  html {
    overflow-y: hidden !important;
  }
</style>
