<template>
  <v-container fill-height>
    <v-row
      v-for="item in weapons"
      :key="weapons.indexOf(item)"
      justify="center"
    >
      <v-card elevation="2" style="width: 100%;" class="weaponCard">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  label="Оружие"
                  prepend-icon="mdi-sword"
                  v-model="item.name"
                  @change="changeWeapon(item)"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                  label="Атрибут"
                  v-model="item.attributeShortName"
                  :items="attributeOptions"
                  @change="changeWeapon(item)"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-switch
                  label="Владение"
                  v-model="item.proficiency"
                  color="pink"
                  @change="changeWeapon(item)"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Доп. бонус к попаданию"
                  v-model.number="item.hitBonus"
                  type="number"
                  @change="changeWeapon(item)"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Кубы урона"
                  :prepend-icon=getDiceIcon(item)
                  v-model="item.damageDie"
                  @change="changeWeapon(item)"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Доп. бонус к урону"
                  v-model.number="item.damageBonus"
                  type="number"
                  @change="changeWeapon(item)"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-combobox
                  label="Тип урона"
                  v-model="item.damageType"
                  :items="damageOptions"
                  @change="changeWeapon(item)"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text--primary">
                Бросок на попадание: d20
                <label>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" small color="pink" dark>
                        {{getWeaponHitBonus(item)[0]}}
                      </v-chip>
                    </template>
                    {{getWeaponHitBonus(item)[1]}}
                  </v-tooltip>
                </label>
              </v-col>
              <v-col class="text--primary">
                Бросок урона: {{ item.damageDie }}
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on" small color="pink" dark>
                      {{getWeaponDamageBonus(item)[0]}}
                    </v-chip>
                  </template>
                  {{getWeaponDamageBonus(item)[1]}}
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                fab
                small
                dark
                color="pink"
                icon
                @click="deleteWeapon(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
              <v-spacer/>
              <v-btn
                dark
                color="pink"
                @click="helpDialog = true"
              >
                ПОМОЩЬ
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row justify="center" v-if="weapons.length < 3">
      <v-btn
        fab
        small
        dark
        color="pink"
        @click="addWeapon()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-dialog
        v-model="helpDialog"
        width="500"
    >
      <v-card>
        <v-card-title>Добавление оружия</v-card-title>
        <v-card-text class="text--primary">
          <p>
            Помимо очевидных параметров есть и несколько неочевидных, касающихся
            урона и попадания.
          </p>
          <p>
            <b>Дополнительный бонус к попаданию</b> - это дополнительный бонус к броску
            на попадание, который добавляется к модификатору атрибута и бонусу владения
            (если оружием владеют). Такой бонус можно получить от свойств оружия (оружия
            +1, например, дают бонус +1 и к попаданию, и к урону); боевого стиля у воинов,
            рейнджеров, паладинов (например, боевой стиль "Стрельба" дает бонус +2 к попаданию
            из лука); некоторых других эффектов. Все такие бонусы необходимо
            просуммировать и занести значение в поле <i>"Дополнительный бонус к попаданию"</i>.
          </p>
          <p>
            <b>Дополнительный бонус к урону</b> - это дополнительный бонус к броску
            урона, который добавляется к модификатору атрибута. Такой бонус можно получить
            от свойств оружия (оружия +1, например, дают бонус +1 и к попаданию, и к урону);
            боевого стиля у воинов, рейнджеров, паладинов (например, боевой стиль "Дуэлянт");
            ярости варвара (хотя заносить ли его сюда - вопрос предпочтения); некоторых других
            эффектов. Все такие бонусы необходимо просуммировать и занести значение в поле
            <i>"Дополнительный бонус к урону"</i>.
          </p>
          <p>
            В поле <b>кубы урона</b> заносятся только сами кубы, как это показано в таблице
            "Оружие" в главе "Снаряжение" (PHB, стр. 149).
          </p>
          <p>
            При наведении на высчитанный бонус в нижней части карточки можно получить
            детали о том, как этот бонус был посчитан.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="helpDialog = false"
            color="primary"
          >Понятно</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {attributes} from "@/data/attributes"
import {damageTypes} from "@/data/damageTypes"

export default {
  name: "WeaponBox",
  data: () => ({
    weapons: [],
    attributeOptions: Object.keys(attributes).map(item => attributes[item].shortName),
    damageOptions: damageTypes,
    helpDialog: false
  }),
  methods: {
    addWeapon() {
      const fstAttr = attributes[Object.keys(attributes)[0]];

      const newWeapon = {
        name: "Молот",
        attribute: fstAttr.name,
        attributeShortName: fstAttr.shortName,
        damageType: this.damageOptions[0],
        damageDie: "1d8",
        hitBonus: 3,
        damageBonus: 1,
        proficiency: true
      }

      this.weapons.push(newWeapon);
      this.$store.commit("addWeapon", newWeapon);
    },
    deleteWeapon(item) {
      const index = this.weapons.indexOf(item);
      this.weapons.splice(index, 1);

      this.$store.commit("removeWeapon", index)
    },
    changeWeapon(item) {
      if (item.attributeShortName !== attributes[item.attribute].shortName) {
        const masterName = Object.keys(attributes).filter(x => attributes[x].shortName === item.attributeShortName)[0];
        item.attribute = masterName;
      }

      const index = this.weapons.indexOf(item);
      this.$store.commit("changeWeapon", {
        index,
        props: item
      });
    },
    getWeaponHitBonus(item) {
      const stateAttr = this.$store.getters.getAttribute(item.attribute);

      let hitBonus = Math.floor((stateAttr.score - 10)/2);
      let hitBonusDesc = [`${hitBonus}(${item.attributeShortName})`];

      if (item.proficiency) {
        const profBonus = this.$store.getters.getProficiencyBonus;
        hitBonus += profBonus;
        hitBonusDesc.push(`${profBonus}(бонус владения)`);
      }

      if (item.hitBonus !== 0) {
        hitBonus += item.hitBonus;
        hitBonusDesc.push(`${item.hitBonus}(доп. бонус)`);
      }

      hitBonus = hitBonus >= 0 ? `+ ${hitBonus}` : `- ${Math.abs(hitBonus)}`
      return [hitBonus, hitBonusDesc.join(' + ')];
    },
    getWeaponDamageBonus(item) {
      const stateAttr = this.$store.getters.getAttribute(item.attribute);

      let damageBonus = Math.floor((stateAttr.score - 10)/2);
      let damageBonusDesc = [`${damageBonus}(${item.attributeShortName})`];

      if (item.damageBonus !== 0) {
        damageBonus += item.damageBonus;
        damageBonusDesc.push(`${item.damageBonus}(доп. бонус)`);
      }

      damageBonus = damageBonus >= 0 ? `+ ${damageBonus}` : `- ${Math.abs(damageBonus)}`
      return [damageBonus, damageBonusDesc.join(' + ')];
    },
    getDiceIcon(item) {
      const re = /d(4|6|8|10|12|20)/;
      const match = re.exec(item.damageDie);

      if (match) {
        return `mdi-dice-${match[0]}`;
      }
      else {
        return "mdi-dice-multiple";
      }
    }
  },
  mounted() {
    this.weapons = this.$store.getters.getWeapons;
  }
}
</script>

<style scoped>
  .weaponCard {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>