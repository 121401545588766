export const example = {
    "characterInfo": {
        "characterName": "Бур'Тубленн Олорук",
        "background": "Гладиатор",
        "playerName": "Андрей",
        "race": "Тифлинг",
        "alignment": "Законопослушный злой",
        "xpPoints": 90000,
        "classes": {}
    },
    "personality": {
        "ideals": "Сила воли - единственное, что достойно уважения.",
        "bonds": "Я люблю свою секиру и уважаю своих соратников.",
        "flaws": "Я люблю вести переговоры, хотя не должен.",
        "personalityTraits": "Я не очень умный."
    },
    "classes": [
        {
            "displayedName": "Варвар",
            "name": "Barbarian",
            "level": 11
        }
    ],
    "attributes": {
        "Str": {
            "score": 18,
            "saveProficiency": true
        },
        "Dex": {
            "score": 16,
            "saveProficiency": false
        },
        "Con": {
            "score": 17,
            "saveProficiency": true
        },
        "Int": {
            "score": 7,
            "saveProficiency": false
        },
        "Wis": {
            "score": 14,
            "saveProficiency": false
        },
        "Cha": {
            "score": 16,
            "saveProficiency": false
        }
    },
    "skills": {
        "Acrobatics": {
            "proficiency": 1
        },
        "Athletics": {
            "proficiency": 0
        },
        "Arcana": {
            "proficiency": 0
        },
        "Deception": {
            "proficiency": 0
        },
        "History": {
            "proficiency": 0
        },
        "Insight": {
            "proficiency": 0
        },
        "Intimidation": {
            "proficiency": 1
        },
        "Investigation": {
            "proficiency": 0
        },
        "Medicine": {
            "proficiency": 0
        },
        "Nature": {
            "proficiency": 0
        },
        "Perception": {
            "proficiency": 0
        },
        "Performance": {
            "proficiency": 1
        },
        "Persuasion": {
            "proficiency": 0
        },
        "Religion": {
            "proficiency": 0
        },
        "SleightOfHand": {
            "proficiency": 0
        },
        "Stealth": {
            "proficiency": 0
        },
        "Survival": {
            "proficiency": 1
        },
        "AnimalHandling": {
            "proficiency": 0
        }
    },
    "proficiencies": {
        "language": "Общий, Инфернальный",
        "armor": "Легкая, Средняя, Щиты",
        "weapon": "Воинское, обычное",
        "tool": "Набор грима, лютня"
    },
    "inventory": {
        "gold": 762,
        "silver": 25,
        "electrum": 15,
        "copper": 6,
        "platinum": 32,
        "backpack": "* Лютня\n* Посеребренная секира\n* Орочья секира\n* Плащ из шкуры лютовепря"
    },
    "weapons": [
        {
            "name": "Секира",
            "attribute": "Str",
            "attributeShortName": "СИЛ",
            "damageType": "Рубящий",
            "damageDie": "1d12",
            "hitBonus": 1,
            "damageBonus": 1,
            "proficiency": true
        },
        {
            "name": "Топорик",
            "attribute": "Dex",
            "attributeShortName": "ЛОВ",
            "damageType": "Колющий",
            "damageDie": "1d6",
            "hitBonus": 0,
            "damageBonus": 0,
            "proficiency": true
        },
        {
            "name": "Укус",
            "attribute": "Str",
            "attributeShortName": "СИЛ",
            "damageType": "Некротический",
            "damageDie": "1d4",
            "hitBonus": 0,
            "damageBonus": 0,
            "proficiency": true
        }
    ],
    "settings": {
        "jackOfAllTrades": false,
        "skillCheckBonus": 0,
        "savingThrowBonus": 0,
        "initiativeBonus": 0
    },
    "encounter": {
        "speed": 40,
        "armorClass": 16,
        "hitPoints": 122
    },
    "features": [
        {
            "name": "Темное зрение",
            "text": "60 фт."
        },
        {
            "name": "Ярость",
            "text": "Проверки и спасброски силы с преимуществом, доп. урон, сопротивление к базовым типам урона"
        },
        {
            "name": "Психические клинки",
            "text": "+2d6 психического урона при попадании за 1 вдохновение"
        },
        {
            "name": "Чувство опасности",
            "text": "Спасбросок ловкости с преимуществом, если видно источник опасности."
        },
        {
            "name": "Дикий инстинкт",
            "text": "Броски инициативы с преимуществом."
        },
        {
            "name": "Тотем лося",
            "text": "Передвижение по местности с удвоенной скоростью."
        },
        {
            "name": "Тотем медведя",
            "text": "В состоянии ярости сопротивление всем видам урона, кроме психического."
        }
    ]
}