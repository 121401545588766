<template>
  <v-card class="elevation-1" :color="this.color" dark rounded>
    <v-card-title class="justify-center">
      <v-icon class="icon">
        {{ this.icon }}
      </v-icon>
      {{ this.displayedName }}
    </v-card-title>

    <v-card-actions class="white text--primary justify-center" align="center">
      <v-container fill-height>
        <v-row>
          <v-text-field
            light
            label="Значение"
            v-model.number="parameterValue"
            type="number"
            @change="actualizeAttribute()"
          />
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EncounterBox",
  props: {
    color: String,
    title: String,
    shortTitle: String,
    encounterParameterName: String,
    icon: String,
  },
  data: () => ({
    parameterValue: 10
  }),
  methods: {
    actualizeAttribute() {
      window.console.log(1);
      this.$store.commit("changeEncounterParameter", {
        encounterParameterName: this.encounterParameterName,
        value: this.parameterValue
      })
    }
  },
  computed: {
    displayedName () {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
        case 'xs': return this.shortTitle
        default: return this.title
      }
    },
  },
  mounted() {
    this.parameterValue = this.$store.getters.getEncounterParameter(this.encounterParameterName);
  }
}
</script>

<style scoped>
.icon {
  margin-right: 10px;
}
</style>