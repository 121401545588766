import { backendEndpoint } from '../config'

export async function generateSheet(character) {
    const response = await fetch(`${backendEndpoint}/generate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(character)
    });

    return response;
}