<template>
  <v-menu
    top
    close-on-click
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        fab
        dark
        color="warning"
        absolute
        left
        bottom
        big
        style="margin-bottom: 60px"
        v-bind="attrs"
        v-on="on"
        x-large
      >
        <v-icon dark>
          mdi-alert-octagram
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="item in $attrs.errors"
        :key="item"
      >
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "ErrorsBox"
}
</script>

<style scoped>

</style>