<template>
  <v-card class="elevation-1" :color="this.color" dark rounded>
    <v-card-title class="justify-center">
      <v-icon class="icon">
        {{ this.icon }}
      </v-icon>
      <v-badge
        color="pink"
        :content="modifier"
      >
        {{ this.title }}
      </v-badge>
    </v-card-title>

    <v-card-actions class="white text--primary justify-center" align="center">
      <v-container fill-height>
        <v-row>
          <v-text-field
            light
            label="Значение атрибута"
            v-model.number="attributeValue"
            type="number"
            @change="actualizeAttribute()"
            :error-messages="valueErrors"
            @input="touch()"
            @blur="touch()"
          />
        </v-row>
        <v-row>
          <v-switch
            light
            v-model="saveProficiency"
            label="Владение спасброском"
            @change="actualizeAttribute()"
            dense
          ></v-switch>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import {validationMixin} from "vuelidate";
import {numeric, minValue, maxValue, required} from "vuelidate/lib/validators";

export default {
  name: "AttributeBox",
  mixins: [validationMixin],
  validations: {
    attributeValue: {required, minValue: minValue(1), maxValue: maxValue(40), numeric}
  },
  props: {
    color: String,
    title: String,
    attributeName: String,
    icon: String
  },
  data: () => ({
    attributeValue: 10,
    saveProficiency: false
  }),
  computed: {
    modifier: function() {
      const raw =  Math.floor((this.attributeValue - 10)/2);

      if (raw >= 0) {
        return `+${raw}`
      }
      else {
        return `${raw}`
      }
    },
    valueErrors() {
      const errors = [];
      !this.$v.attributeValue.required && errors.push(`Значение атрибута ${this.title} обязательное`);
      !this.$v.attributeValue.minValue && errors.push(`Значение атрибута ${this.title} не может быть меньше 1`);
      !this.$v.attributeValue.maxValue && errors.push(`Значение атрибута ${this.title} не может быть больше 30`);
      !this.$v.attributeValue.numeric && errors.push(`Значение атрибута ${this.title} должно быть числовым`);
      return errors
    }
  },
  methods: {
    actualizeAttribute() {
      this.$store.commit("changeAttribute", {
        attribute: this.attributeName,
        score: this.attributeValue,
        saveProficiency: this.saveProficiency
      });
    },
    touch() {
      this.$emit('onValidationChange', {
        attr: this.attributeName,
        valid: !this.$v.$invalid,
        errors: this.valueErrors
      });
      this.$v.attributeValue.$touch();
    },
  },
  mounted() {
    const storeAttr = this.$store.getters.getAttribute(this.attributeName);

    this.attributeValue = storeAttr.score;
    this.saveProficiency = storeAttr.saveProficiency;

    this.touch();
  }
}
</script>

<style scoped>
  .icon {
    margin-right: 10px;
  }
</style>