export const attributes = {
    Str: {
        name: "Str",
        displayedName: "Сила",
        shortName: "СИЛ"
    },
    Dex: {
        name: "Dex",
        displayedName: "Ловкость",
        shortName: "ЛОВ"
    },
    Con: {
        name: "Con",
        displayedName: "Телосложение",
        shortName: "ТЕЛ"
    },
    Int: {
        name: "Int",
        displayedName: "Интеллект",
        shortName: "ИНТ"
    },
    Wis: {
        name: "Wis",
        displayedName: "Мудрость",
        shortName: "МДР"
    },
    Cha: {
        name: "Cha",
        displayedName: "Харизма",
        shortName: "ХАР"
    }
}