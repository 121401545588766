import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import { emptyCharacter } from "@/data/emptyCharacter";
import { migrations } from "@/data/migrations";

Vue.use(Vuex);

const latestVersion = 2;

export default new Vuex.Store({
  state: {
    character: {
      characterInfo: {
        characterName: "",
        background: "",
        playerName: "",
        race: "",
        alignment: "",
        xpPoints: "",
      },
      personality: {
        ideals: "",
        bonds: "",
        flaws: "",
        personalityTraits: "",
      },
      classes: [],
      attributes: {
        Str: {
          score: 10,
          saveProficiency: false,
        },
        Dex: {
          score: 10,
          saveProficiency: false,
        },
        Con: {
          score: 10,
          saveProficiency: false,
        },
        Int: {
          score: 10,
          saveProficiency: false,
        },
        Wis: {
          score: 10,
          saveProficiency: false,
        },
        Cha: {
          score: 10,
          saveProficiency: false,
        },
      },
      skills: {
        Acrobatics: { proficiency: 0 },
        Athletics: { proficiency: 0 },
        Arcana: { proficiency: 0 },
        Deception: { proficiency: 0 },
        History: { proficiency: 0 },
        Insight: { proficiency: 0 },
        Intimidation: { proficiency: 0 },
        Investigation: { proficiency: 0 },
        Medicine: { proficiency: 0 },
        Nature: { proficiency: 0 },
        Perception: { proficiency: 0 },
        Performance: { proficiency: 0 },
        Persuasion: { proficiency: 0 },
        Religion: { proficiency: 0 },
        SleightOfHand: { proficiency: 0 },
        Stealth: { proficiency: 0 },
        Survival: { proficiency: 0 },
        AnimalHandling: { proficiency: 0 },
        Technology: { proficiency: 0 },
      },
      proficiencies: {
        language: "",
        armor: "",
        weapon: "",
        tool: "",
      },
      inventory: {
        gold: 0,
        silver: 0,
        electrum: 0,
        copper: 0,
        platinum: 0,
        backpack: "",
      },
      weapons: [],
      settings: {
        jackOfAllTrades: false,
        skillCheckBonus: 0,
        savingThrowBonus: 0,
        initiativeBonus: 0,
        passivePerceptionAdvantage: false,
        useC2Options: false,
      },
      spellcasting: [],
      encounter: {
        speed: 30,
        armorClass: 10,
        hitPoints: 10,
      },
      features: [],
    },
  },
  mutations: {
    changeSkill(state, { skill, proficiency }) {
      state.character.skills[skill].proficiency = proficiency;
    },
    changeInfo(state, { field, value }) {
      state.character.characterInfo[field] = value;
    },
    changePersonality(state, { field, value }) {
      state.character.personality[field] = value;
    },
    changeAttribute(state, { attribute, score, saveProficiency }) {
      state.character.attributes[attribute].score = score;
      state.character.attributes[attribute].saveProficiency = saveProficiency;
    },
    changeClass(state, { classIndex, level, name, displayedName }) {
      state.character.classes[classIndex].name = name;
      state.character.classes[classIndex].level = level;
      state.character.classes[classIndex].displayedName = displayedName;
    },
    addClass(state, classProps) {
      state.character.classes.push(Object.assign({}, classProps));
    },
    removeClass(state, classIndex) {
      state.character.classes.splice(classIndex, 1);
    },
    changeProficiency(state, { field, value }) {
      state.character.proficiencies[field] = value;
    },
    changeWeapon(state, { index, props }) {
      Object.assign(state.character.weapons[index], props);
    },
    addWeapon(state, props) {
      state.character.weapons.push(Object.assign({}, props));
    },
    removeWeapon(state, index) {
      state.character.weapons.splice(index, 1);
    },
    changeEncounterParameter(state, { encounterParameterName, value }) {
      state.character.encounter[encounterParameterName] = value;
    },
    addFeature(state, props) {
      state.character.features.push(Object.assign({}, props));
    },
    removeFeature(state, index) {
      state.character.features.splice(index, 1);
    },
    changeFeature(state, { index, props }) {
      Object.assign(state.character.features[index], props);
    },
    changeInventory(state, { field, value }) {
      state.character.inventory[field] = value;
    },
    changeSetting(state, { field, value }) {
      state.character.settings[field] = value;
    },
    clearChanges(state) {
      state.character = Object.assign({}, emptyCharacter);
    },
    setCharacter(state, character) {
      state.character = Object.assign({}, character);
    },
    checkVersion(state) {
      // Determine version
      let current_version = 0;
      if (state.version !== undefined) {
        current_version = state.version;
      }

      //Update until migrated to the latest
      while (current_version !== latestVersion) {
        window.console.log(
          `Migrating from version ${current_version} to ${current_version + 1}`
        );
        state = migrations[current_version](state);

        current_version++;
      }
      state.version = current_version;
    },
    addSpellcastingOption(state, props) {
      state.character.spellcasting.push(Object.assign({}, props));
    },
    removeSpellcastingOption(state, index) {
      state.character.spellcasting.splice(index, 1);
    },
    changeSpellcastingOption(state, { index, props }) {
      Object.assign(state.character.spellcasting[index], props);
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
  getters: {
    getSkill: (state) => (skill) => {
      return state.character.skills[skill];
    },
    getInfo(state) {
      return state.character.characterInfo;
    },
    getPersonality(state) {
      return state.character.personality;
    },
    getClasses(state) {
      return Array.from(state.character.classes);
    },
    getProficiencies(state) {
      return Object.assign({}, state.character.proficiencies);
    },
    getCurrentState(state) {
      return Object.assign({}, state.character);
    },
    getAttribute: (state) => (attribute) => {
      return state.character.attributes[attribute];
    },
    getSettings(state) {
      return Object.assign(state.character.settings);
    },
    getWeapons(state) {
      return Array.from(state.character.weapons);
    },
    getProficiencyBonus(state) {
      if (state.character.classes.length === 0) {
        return 0;
      }

      const playerLevel = state.character.classes.reduce(
        (accumulator, value) => {
          return accumulator + value.level;
        },
        0
      );

      return 1 + Math.ceil(playerLevel / 4);
    },
    getEncounterParameter: (state) => (encounterParameterName) => {
      return state.character.encounter[encounterParameterName];
    },
    getFeatures(state) {
      return Array.from(state.character.features);
    },
    getSpellcastingOptions(state) {
      return Array.from(state.character.spellcasting);
    },
    getInventory(state) {
      return Object.assign({}, state.character.inventory);
    },
    getCharacter(state) {
      return Object.assign({}, state.character);
    },
  },
});
