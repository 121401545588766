<template>

  <v-simple-table dense style="width: 100%">

    <template>

      <thead>

        <tr>

          <th> Владение / Компетенция </th>

          <th> Навык </th>

          <th> Модификатор </th>

          <th> Атрибут </th>

        </tr>

      </thead>

      <tbody>

        <tr v-for="item in skills" :key="item.name">

          <td>

            <v-btn @click="clickProficiency(item)" small icon color="pink">

              <v-icon v-if="item.proficiency === 0">
                 mdi-checkbox-blank-circle-outline
              </v-icon>

              <v-icon v-if="item.proficiency === 1">
                 mdi-checkbox-blank-circle
              </v-icon>

              <v-icon v-if="item.proficiency === 2">mdi-star-circle</v-icon>

            </v-btn>

          </td>

          <td> {{ item.displayedName }} </td>

          <td> {{ item.modifier }} </td>

          <td> {{ item.attribute }} </td>

        </tr>

      </tbody>

    </template>

  </v-simple-table>

</template>

<script>
import { skillList } from "@/data/skills";
import { attributes } from "@/data/attributes";

export default {
  name: "SkillBox",
  props: {
    skillName: String,
    displayedName: String,
    test: Boolean,
  },
  data: () => ({
    skillList: [],
  }),
  methods: {
    clickProficiency(item) {
      const stateSkill = this.$store.getters.getSkill(item.name);

      let proficiency = (stateSkill.proficiency += 1);
      if (proficiency === 3) {
        proficiency = 0;
      }

      this.$store.commit("changeSkill", {
        skill: item.name,
        proficiency,
      });
    },
  },
  mounted() {},
  computed: {
    skills: function() {
      let skills = [];

      let effectiveSkillList = skillList.slice();
      if (this.$store.getters.getSettings.useC2Options) {
        effectiveSkillList.push({
          name: "Technology",
          displayedName: "Техника",
          attribute: "Int",
        });
      }

      for (const x of effectiveSkillList) {
        const proficiencyBonus = this.$store.getters.getProficiencyBonus;

        const stateSkill = this.$store.getters.getSkill(x.name);
        const stateAttr = this.$store.getters.getAttribute(x.attribute);

        let modifier =
          Math.floor((stateAttr.score - 10) / 2) +
          proficiencyBonus * stateSkill.proficiency;
        if (this.$store.getters.getSettings.jackOfAllTrades) {
          modifier += Math.floor(proficiencyBonus / 2);
        }
        modifier += this.$store.getters.getSettings.skillCheckBonus;

        modifier = modifier >= 0 ? `+${modifier}` : `${modifier}`;

        skills.push({
          name: x.name,
          displayedName: x.displayedName,
          attribute: attributes[x.attribute].displayedName,
          proficiency: stateSkill.proficiency,
          modifier,
        });
      }

      return skills;
    },
  },
};
</script>

<style scoped>
.expertise {
  margin-left: 20px;
}
</style>

