<template>
    <v-container fill-height>
      <v-row>
        <v-col cols="4">
          <v-row v-for="coin in coins" :key="coin.name">
            <v-text-field
              :label="coin.displayedName"
              :color="coin.color"
              v-model.number = "coin.quantity"
              type="number"
              @change="$store.commit('changeInventory', {field: coin.name, value: coin.quantity})"
            >
              <v-icon slot="prepend" :color="coin.color">mdi-circle-multiple</v-icon>
            </v-text-field>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-textarea
            outlined
            rows="11"
            row-height="15"
            label="Рюкзак"
            v-model="inventory"
            prepend-icon="mdi-bag-personal"
            no-resize
            :rules="[v => (v).length <= 600 || 'Допускается не более 300 символов.']"
            counter
            @change="$store.commit('changeInventory', {field: 'backpack', value: inventory})"
            :error-messages="inventoryErrors"
            @input="touch()"
            @blur="touch()"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import {validationMixin} from "vuelidate";
import {maxLength} from "vuelidate/lib/validators";

export default {
  name: "InventoryBox",
  mixins: [validationMixin],
  validations: {
    inventory: {maxLength: maxLength(600)}
  },
  data: () => ({
    coins: [
      {
        name: 'copper',
        color: 'orange darken-2',
        displayedName: 'Медяки',
        quantity: 0
      },
      {
        name: 'silver',
        color: 'grey lighten-1',
        displayedName: 'Серебро',
        quantity: 0
      },
      {
        name: 'electrum',
        color: 'blue-grey lighten-1',
        displayedName: 'Электрум',
        quantity: 0
      },
      {
        name: 'gold',
        color: 'amber',
        displayedName: 'Золото',
        quantity: 0
      },
      {
        name: 'platinum',
        color: 'blue-grey lighten-4',
        displayedName: 'Платина',
        quantity: 0
      },
    ],
    inventory: ""
  }),
  mounted() {
    const stateInventory = this.$store.getters.getInventory;
    for (let coin of this.coins) {
      coin.quantity = stateInventory[coin.name]
    }
    this.inventory = stateInventory.backpack;

    this.touch();
  },
  computed: {
    inventoryErrors() {
      const errors = [];
      !this.$v.inventory.maxLength && errors.push(`Описание инвентаря не должно превышать 600 символов`);
      return errors
    }
  },
  methods: {
    touch() {
      this.$emit('onValidationChange', {
        attr: 'inventory',
        valid: !this.$v.$invalid,
        errors: this.inventoryErrors
      });
      this.$v.inventory.$touch();
    },
  }
}
</script>

<style scoped>

</style>