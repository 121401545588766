<template>
  <v-container fill-height>
    <v-row
      v-for="item in characterFeatures"
      :key="characterFeatures.indexOf(item)"
      justify="center"
    >
      <v-card elevation="2" style="width: 100%;" class="featureCard">
        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field
                label="Название"
                v-model="item.name"
                @change="changeFeature(item)"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-textarea
                label="Описание"
                v-model="item.text"
                :rules="[v => (v).length <= 200 || 'Допускается не более 200 символов']"
                counter
                auto-grow
                rows="1"
                row-height="15"
                @change="changeFeature(item)"
              ></v-textarea>
            </v-row>
            <v-row>
              <v-btn
                fab
                small
                dark
                color="pink"
                icon
                @click="deleteFeature(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row justify="center">
      <v-btn
          fab
          small
          dark
          color="pink"
          @click="addFeature()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FeaturesBox",
  data: () => ({
    characterFeatures: [],
  }),
  methods: {
    addFeature() {
      const newFeature = {
        name: "",
        text: ""
      };

      this.characterFeatures.push(Object.assign({}, newFeature));
      this.$store.commit("addFeature", newFeature);
    },
    deleteFeature(item) {
      const index = this.characterFeatures.indexOf(item);
      this.characterFeatures.splice(index, 1);
      this.$store.commit("removeFeature", index);
    },
    changeFeature(item) {
      this.$store.commit("changeFeature", {
        index: this.characterFeatures.indexOf(item),
        props: item
      })
    }
  },
  mounted() {
    this.characterFeatures = this.$store.getters.getFeatures;
  }
}
</script>

<style scoped>
  .featureCard {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>