<template>
  <v-dialog
      v-model="dialogEnabled"
      persistent
      max-width="350"
  >
    <v-card class=text-center>
      <v-card-title class="justify-center">Импорт листа персонажа</v-card-title>
      <v-card-text>
        <p>
          Импортируйте json-файл персонажа, сгенерированный на этом сервисе.
        </p>
        <v-progress-circular
            indeterminate
            color="pink"
            :size="50"
            v-if="loading"
        ></v-progress-circular>
        <v-form v-model="valid">
          <v-file-input
              truncate-length="21"
              v-if="!loading"
              label="Файл персонажа"
              accept=".json"
              :rules="rules"
              v-model="characterFile"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-btn
          v-if="!loading"
          color="pink"
          :disabled="!characterFile || !valid"
          @click="processImport"
          :dark="characterFile !== null && valid"
      >
        <v-icon left>
          mdi-cog
        </v-icon>
        Импортировать
      </v-btn>
      <v-card-actions class="justify-end">
        <v-btn
            @click.stop="clickCancel()"
            text
            color="pink"
            :disabled="loading"
        >Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImportSheetModal",
  data: () => ({
    loading: false,
    rules: [
        value => value ? value.name.endsWith('.json') : true || 'Файл должен быть в расширении .json'
    ],
    valid: false,
    characterFile: null
  }),
  props: {
    value: Boolean
  },
  computed: {
    dialogEnabled: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    clickCancel() {
      this.dialogEnabled = false;
      this.loading = false;
      this.characterFile = null;
    },
    processImport() {
      window.console.log(this.characterFile);

      this.loading = true;

      // Stuff to check parsing status
      let parsed = false;
      let parsedCharacterFile = null;


      // Check if file was parsed and do some finishing work
      const check = () => {
        if (parsed) {
          window.console.log(parsedCharacterFile);

          // Commit to store
          this.$store.commit('setCharacter', parsedCharacterFile);
          this.$store.commit('checkVersion');

          // Finishing
          this.loading = false;
          this.characterFile = null;
          alert("Файл импортирован!");
          location.reload();
        } else {
          setTimeout(check, 1000);
        }
      }
      check();

      const reader = new FileReader();
      reader.readAsText(this.characterFile);
      reader.onload = function (evt) {
        parsedCharacterFile = JSON.parse(evt.target.result);
        parsed = true;
      }

    }
  }
}
</script>

<style scoped>

</style>