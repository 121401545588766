<template>
  <v-container fill-height>
    <v-row
        v-for="item in spellcastingOptions"
        :key="spellcastingOptions.indexOf(item)"
        justify="center"
    >
      <v-card elevation="2" style="width: 100%;" class="spellcastingCard">
        <v-card-text>
          <v-container>
            <v-row justify="space-around">
              <v-col cols="4">
                <v-select
                  label="Атрибут"
                  v-model="item.attributeShortName"
                  :items="attributeOptions"
                  @change="changeSpellcastingOption(item)"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Бонус к броску атаки заклинанием"
                  v-model.number="item.rollBonus"
                  type="number"
                  @change="changeSpellcastingOption(item)"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="Бонус к спасброску"
                    v-model.number="item.saveBonus"
                    type="number"
                    @change="changeSpellcastingOption(item)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                  fab
                  small
                  dark
                  color="pink"
                  icon
                  @click="deleteSpellcastingOption(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row justify="center">
      <v-btn
          fab
          small
          dark
          color="pink"
          @click="addSpellcastingOption()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import {attributes} from "@/data/attributes";

export default {
  name: "SpellcastingBox",
  data: () => ({
    spellcastingOptions: [],
    attributeOptions: Object.keys(attributes).map(item => attributes[item].shortName),
  }),
  methods: {
    changeSpellcastingOption(option) {
      if (option.attributeShortName !== attributes[option.attribute].shortName) {
        const masterName = Object.keys(attributes).filter(x => attributes[x].shortName === option.attributeShortName)[0];
        option.attribute = masterName;
      }

      this.$store.commit("changeSpellcastingOption", {
        index: this.spellcastingOptions.indexOf(option),
        props: option
      })
    },
    addSpellcastingOption() {
      const fstAttr = attributes[Object.keys(attributes)[0]];

      const newOption = {
        attribute: fstAttr.name,
        attributeShortName: fstAttr.shortName,
        rollBonus: 0,
        saveBonus: 0
      }

      this.spellcastingOptions.push(newOption);
      this.$store.commit("addSpellcastingOption", newOption);
    },
    deleteSpellcastingOption(option) {
      const index = this.spellcastingOptions.indexOf(option);
      this.spellcastingOptions.splice(index, 1);

      this.$store.commit("removeSpellcastingOption", index);
    }
  },
  mounted() {
    this.spellcastingOptions = this.$store.getters.getSpellcastingOptions;
  }
}
</script>

<style scoped>
.spellcastingCard {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>