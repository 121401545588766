<template>

  <main style="margin: auto">

    <ErrorsBox :errors="validationErrors" v-if="!isValid" />

    <GenerateSheetModal v-model="generationDialog"></GenerateSheetModal>

    <ImportSheetModal v-model="importDialog"></ImportSheetModal>

    <v-app-bar dark flat elevate-on-scroll scroll-target="#scrollable" absolute>

      <v-menu bottom left>

        <template v-slot:activator="{ on, attrs }">

          <v-app-bar-nav-icon v-bind="attrs" v-on="on"> </v-app-bar-nav-icon>

        </template>

        <v-list>

          <v-list-item>

            <v-btn text block @click="clearData()">

              <v-icon left dark> mdi-sync </v-icon>
               Сбросить изменения
            </v-btn>

          </v-list-item>

          <v-divider />

          <v-list-item>

            <v-btn text block @click="uploadJson()">

              <v-icon left dark> mdi-file-upload </v-icon>
               Импортировать файл персонажа
            </v-btn>

          </v-list-item>

          <v-list-item>

            <v-btn text block @click="downloadCharacterJson()">

              <v-icon left dark> mdi-file-download </v-icon>
               Скачать файл персонажа
            </v-btn>

          </v-list-item>

          <v-divider />

          <v-list-item>

            <v-btn text block @click="generateList()" :disabled="!isValid">

              <v-icon left dark> mdi-script-text-play </v-icon>
               Сгенерировать лист
            </v-btn>

          </v-list-item>

          <v-divider />

          <v-list-item>

            <v-btn text block :href="googleFeedbackForm" target="_blank">

              <v-icon left dark> mdi-message-alert </v-icon>
               Обратная связь
            </v-btn>

          </v-list-item>

        </v-list>

      </v-menu>

      <v-toolbar-title>Редактор листов персонажей</v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension>

        <v-tabs v-model="tab" center-active fixed-tabs>

          <v-tab>Основная информация</v-tab>

          <v-tab>Навыки и атрибуты</v-tab>

          <v-tab>Бой и инвентарь</v-tab>

          <v-tab>Использование заклинаний</v-tab>

          <v-tab>Оружие</v-tab>

          <v-tab>Личность и владения</v-tab>

          <v-tab>Особенности</v-tab>

          <v-tab>Дополнительно</v-tab>

        </v-tabs>

      </template>

    </v-app-bar>

    <v-sheet id="scrollable" class="overflow-y-auto" :height="windowHeight">

      <v-tabs-items v-model="tab" class="tab-content">

        <v-tab-item>

          <v-container fill-height>

            <v-row justify="center">

              <h2>Основная информация</h2>

            </v-row>

            <v-row justify="center">

              <v-card
                style="width: 100%; margin-top: 10px; margin-bottom: 10px"
              >

                <v-card-text>

                  <v-container>

                    <v-row>

                      <v-col>

                        <v-text-field
                          label="Имя персонажа"
                          v-model="characterName"
                          @change="
                            $store.commit('changeInfo', {
                              field: 'characterName',
                              value: characterName,
                            })
                          "
                        ></v-text-field>

                      </v-col>

                    </v-row>

                    <v-row justify="center">

                      <v-col>

                        <v-text-field
                          label="Раса"
                          v-model="race"
                          @change="
                            $store.commit('changeInfo', {
                              field: 'race',
                              value: race,
                            })
                          "
                        ></v-text-field>

                      </v-col>

                      <v-col>

                        <v-text-field
                          label="Предыстория"
                          v-model="background"
                          @change="
                            $store.commit('changeInfo', {
                              field: 'background',
                              value: background,
                            })
                          "
                        ></v-text-field>

                      </v-col>

                      <v-col>

                        <v-text-field
                          label="Имя игрока"
                          v-model="playerName"
                          @change="
                            $store.commit('changeInfo', {
                              field: 'playerName',
                              value: playerName,
                            })
                          "
                        ></v-text-field>

                      </v-col>

                    </v-row>

                    <v-row>

                      <v-col>

                        <v-text-field
                          label="Очки опыта"
                          v-model.number="xpPoints"
                          type="number"
                          @change="
                            $store.commit('changeInfo', {
                              field: 'xpPoints',
                              value: xpPoints,
                            })
                          "
                        ></v-text-field>

                      </v-col>

                      <v-col>

                        <v-select
                          label="Мировоззрение"
                          v-model="alignment"
                          :items="alignmentOptions"
                          :menu-props="{ bottom: true, offsetY: true }"
                          @change="
                            $store.commit('changeInfo', {
                              field: 'alignment',
                              value: alignment,
                            })
                          "
                        ></v-select>

                      </v-col>

                    </v-row>

                  </v-container>

                </v-card-text>

              </v-card>

            </v-row>

            <v-row justify="center">

              <h2>Классы</h2>

            </v-row>

            <v-row justify="center">

              <ClassBox />

            </v-row>

          </v-container>

        </v-tab-item>

        <v-tab-item eager>

          <v-container fill-height>

            <v-row justify="center">

              <h2>Атрибуты</h2>

            </v-row>

            <v-row justify="center">

              <v-col>

                <AttributeBox
                  title="Сила"
                  attributeName="Str"
                  icon="mdi-arm-flex"
                  color="blue"
                  @onValidationChange="attributeValidationChanged"
                ></AttributeBox>

              </v-col>

              <v-col>

                <AttributeBox
                  title="Ловкость"
                  attributeName="Dex"
                  icon="mdi-karate"
                  color="blue"
                  @onValidationChange="attributeValidationChanged"
                ></AttributeBox>

              </v-col>

              <v-col>

                <AttributeBox
                  title="Телосложение"
                  attributeName="Con"
                  icon="mdi-kettlebell"
                  color="blue"
                  @onValidationChange="attributeValidationChanged"
                ></AttributeBox>

              </v-col>

            </v-row>

            <v-row justify="center">

              <v-col>

                <AttributeBox
                  title="Интеллект"
                  attributeName="Int"
                  icon="mdi-script"
                  color="blue"
                  @onValidationChange="attributeValidationChanged"
                ></AttributeBox>

              </v-col>

              <v-col>

                <AttributeBox
                  title="Мудрость"
                  attributeName="Wis"
                  icon="mdi-owl"
                  color="blue"
                  @onValidationChange="attributeValidationChanged"
                ></AttributeBox>

              </v-col>

              <v-col>

                <AttributeBox
                  title="Харизма"
                  attributeName="Cha"
                  icon="mdi-sunglasses"
                  color="blue"
                  @onValidationChange="attributeValidationChanged"
                ></AttributeBox>

              </v-col>

            </v-row>

            <v-row justify="center">

              <h2>Навыки</h2>

            </v-row>

            <v-row justify="center">

              <p>
                Для указания компетенции нажмите на кнопку в первом столбце
                дважды.
              </p>

            </v-row>

            <v-row justify="center">

              <SkillBox />

            </v-row>

          </v-container>

        </v-tab-item>

        <v-tab-item eager>

          <v-container fill-height>

            <v-row justify="center">

              <h2>Бой</h2>

            </v-row>

            <v-row justify="center">

              <v-col>

                <EncounterBox
                  title="Класс брони"
                  shortTitle="КД"
                  encounterParameterName="armorClass"
                  icon="mdi-shield"
                  color="orange"
                ></EncounterBox>

              </v-col>

              <v-col>

                <EncounterBox
                  title="Скорость"
                  shortTitle="Скор."
                  encounterParameterName="speed"
                  icon="mdi-run-fast"
                  color="green"
                ></EncounterBox>

              </v-col>

              <v-col>

                <EncounterBox
                  title="Очки здоровья"
                  shortTitle="ОЗ"
                  encounterParameterName="hitPoints"
                  icon="mdi-heart"
                  color="red"
                ></EncounterBox>

              </v-col>

            </v-row>

            <v-row justify="center">

              <h2>Инвентарь</h2>

            </v-row>

            <v-row justify="center">

              <InventoryBox @onValidationChange="attributeValidationChanged" />

            </v-row>

          </v-container>

        </v-tab-item>

        <v-tab-item>

          <v-container fill-height>

            <v-row justify="center">

              <h2>Модификаторы заклинаний</h2>

            </v-row>

            <v-row justify="center">

              <p>
                Если ваш класс обладает способностью применять заклинания,
                укажите атрибут в этой секции.
              </p>

            </v-row>

            <v-row justify="center">

              <SpellcastingBox />

            </v-row>

          </v-container>

        </v-tab-item>

        <v-tab-item>

          <v-container fill-height>

            <v-row justify="center">

              <h2>Оружие</h2>

            </v-row>

            <v-row justify="center">

              <WeaponBox />

            </v-row>

          </v-container>

        </v-tab-item>

        <v-tab-item>

          <v-container fill-height>

            <v-row justify="center">

              <h2>Личность</h2>

            </v-row>

            <v-row justify="center">

              <v-col>

                <v-textarea
                  :rules="[
                    (v) => v.length <= 99 || 'Допускается не более 99 символов',
                  ]"
                  counter
                  auto-grow
                  outlined
                  rows="5"
                  row-height="15"
                  label="Персональные черты"
                  v-model="personalityTraits"
                  @change="
                    $store.commit('changePersonality', {
                      field: 'personalityTraits',
                      value: personalityTraits,
                    })
                  "
                ></v-textarea>

              </v-col>

              <v-col>

                <v-textarea
                  :rules="[
                    (v) => v.length <= 99 || 'Допускается не более 99 символов',
                  ]"
                  counter
                  auto-grow
                  outlined
                  rows="5"
                  row-height="15"
                  label="Привязанности"
                  v-model="bonds"
                  @change="
                    $store.commit('changePersonality', {
                      field: 'bonds',
                      value: bonds,
                    })
                  "
                ></v-textarea>

              </v-col>

            </v-row>

            <v-row justify="center">

              <v-col>

                <v-textarea
                  :rules="[
                    (v) => v.length <= 99 || 'Допускается не более 99 символов',
                  ]"
                  counter
                  auto-grow
                  outlined
                  rows="5"
                  row-height="15"
                  label="Идеалы"
                  v-model="ideals"
                  @change="
                    $store.commit('changePersonality', {
                      field: 'ideals',
                      value: ideals,
                    })
                  "
                ></v-textarea>

              </v-col>

              <v-col>

                <v-textarea
                  :rules="[
                    (v) => v.length <= 99 || 'Допускается не более 99 символов',
                  ]"
                  counter
                  auto-grow
                  outlined
                  rows="5"
                  row-height="15"
                  label="Пороки"
                  v-model="flaws"
                  @change="
                    $store.commit('changePersonality', {
                      field: 'flaws',
                      value: flaws,
                    })
                  "
                ></v-textarea>

              </v-col>

            </v-row>

            <v-row justify="center">

              <h2>Владения</h2>

            </v-row>

            <v-row justify="center">

              <v-col>

                <v-textarea
                  :rules="[
                    (v) => v.length <= 99 || 'Допускается не более 99 символов',
                  ]"
                  counter
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Владение оружием"
                  v-model="proficiencies.weapon"
                  @change="
                    $store.commit('changeProficiency', {
                      field: 'weapon',
                      value: proficiencies.weapon,
                    })
                  "
                ></v-textarea>

              </v-col>

              <v-col>

                <v-textarea
                  :rules="[
                    (v) => v.length <= 99 || 'Допускается не более 99 символов',
                  ]"
                  counter
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Владение инструментами"
                  v-model="proficiencies.tool"
                  @change="
                    $store.commit('changeProficiency', {
                      field: 'tool',
                      value: proficiencies.tool,
                    })
                  "
                ></v-textarea>

              </v-col>

            </v-row>

            <v-row justify="center">

              <v-col>

                <v-textarea
                  :rules="[
                    (v) => v.length <= 99 || 'Допускается не более 99 символов',
                  ]"
                  counter
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Владение броней"
                  v-model="proficiencies.armor"
                  @change="
                    $store.commit('changeProficiency', {
                      field: 'armor',
                      value: proficiencies.armor,
                    })
                  "
                ></v-textarea>

              </v-col>

              <v-col>

                <v-textarea
                  :rules="[
                    (v) => v.length <= 99 || 'Допускается не более 99 символов',
                  ]"
                  counter
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Владение языками"
                  v-model="proficiencies.language"
                  @change="
                    $store.commit('changeProficiency', {
                      field: 'language',
                      value: proficiencies.language,
                    })
                  "
                ></v-textarea>

              </v-col>

            </v-row>

          </v-container>

        </v-tab-item>

        <v-tab-item>

          <v-container fill-height>

            <v-row justify="center">

              <h2>Особенности и способности</h2>

            </v-row>

            <v-row justify="center" class="text-center">

              <p>
                 Все, что обычно записывается в большое поле "Особенности и способности":
                классовые и расовые способности, черты, прочая информация, которую
                нужно дополнительно зафиксировать.
              </p>

              <p>
                 Например, в поле "Название" можно занести "Чувство опасности", а
                в "Описание" - "Спасбросок ловкости с преимуществом, если видно источник
                опасности."
              </p>

            </v-row>

            <v-row justify="center">

              <FeaturesBox />

            </v-row>

          </v-container>

        </v-tab-item>

        <v-tab-item>

          <v-container fill-height>

            <v-row justify="center">

              <h2>Дополнительные опции</h2>

            </v-row>

            <v-row justify="center" align-self="center">

              <v-col cols="4">

                <v-switch
                  label="Мастер на все руки"
                  v-model="settings.jackOfAllTrades"
                  @change="
                    $store.commit('changeSetting', {
                      field: 'jackOfAllTrades',
                      value: settings.jackOfAllTrades,
                    })
                  "
                ></v-switch>

              </v-col>

              <v-col cols="8">

                <p class="text-body-2">
                   Способность бардов. Ко всем проверкам характеристик, куда не включен
                  бонус владения, добавляется половина бонуса владения, округленная
                  в меньшую сторону.
                </p>

              </v-col>

            </v-row>

            <v-row justify="center" align-self="center">

              <v-col cols="4">

                <v-switch
                  label="Преимущество на внимательность"
                  v-model="settings.passivePerceptionAdvantage"
                  @change="
                    $store.commit('changeSetting', {
                      field: 'passivePerceptionAdvantage',
                      value: settings.passivePerceptionAdvantage,
                    })
                  "
                ></v-switch>

              </v-col>

              <v-col cols="8">

                <p class="text-body-2">
                   Есть ли у персонажа преимущество на проверки внимательности. Используется
                  для вычисления пассивной внимательности.
                </p>

              </v-col>

            </v-row>

            <v-row justify="center" align-self="center">

              <v-col cols="4">

                <v-switch
                  label="Дополнительные опции"
                  v-model="settings.useC2Options"
                  @change="
                    $store.commit('changeSetting', {
                      field: 'useC2Options',
                      value: settings.useC2Options,
                    })
                  "
                ></v-switch>

              </v-col>

              <v-col cols="8">

                <p class="text-body-2"> Использовать дополнительные опции. </p>

              </v-col>

            </v-row>

            <v-row justify="center">

              <h2>Бонусы к броскам</h2>

            </v-row>

            <v-row justify="center">

              <v-col>

                <p>
                   В каком-то смысле следующие три настройки можно бы было объединить
                  в одну, однако я решил представить этот момент как три разные опции
                  для большей вариативности.
                </p>

                <p>
                   Например, для использования
                  <a
                    href="https://www.dndbeyond.com/magic-items/stone-of-good-luck-luckstone"
                    target="_blank"
                  >
                    Камня Удачи
                  </a>
                   необходимо занести "1" в первые два поля. Но для некоторых других
                  ситуаций может потребоваться более тонкая настройка.
                </p>

              </v-col>

            </v-row>

            <v-row justify="center" align-self="center">

              <v-col cols="4">

                <v-text-field
                  label="Бонус к проверкам характеристик"
                  v-model.number="settings.skillCheckBonus"
                  type="number"
                  @change="
                    $store.commit('changeSetting', {
                      field: 'skillCheckBonus',
                      value: settings.skillCheckBonus,
                    })
                  "
                ></v-text-field>

              </v-col>

              <v-col cols="8">

                <p class="text-body-2">
                   Фиксированный бонус, добавляющийся ко всем проверкам характеристик
                  (не включая инициативу, см. поле ниже).
                </p>

              </v-col>

            </v-row>

            <v-row justify="center" align-self="center">

              <v-col cols="4">

                <v-text-field
                  label="Бонус к спасброскам"
                  v-model.number="settings.savingThrowBonus"
                  type="number"
                  @change="
                    $store.commit('changeSetting', {
                      field: 'savingThrowBonus',
                      value: settings.savingThrowBonus,
                    })
                  "
                ></v-text-field>

              </v-col>

              <v-col cols="8">

                <p class="text-body-2">
                   Фиксированный бонус, добавляющийся ко всем спасброскам.
                </p>

              </v-col>

            </v-row>

            <v-row justify="center" align-self="end">

              <v-col cols="4">

                <v-text-field
                  label="Бонус к инициативе"
                  v-model.number="settings.initiativeBonus"
                  type="number"
                  @change="
                    $store.commit('changeSetting', {
                      field: 'initiativeBonus',
                      value: settings.initiativeBonus,
                    })
                  "
                ></v-text-field>

              </v-col>

              <v-col cols="8">

                <p class="text-body-2">
                   Фиксированный бонус, добавляющийся к броску инициативы. Добавляется
                  поверх бонуса к проверкам характеристик.
                </p>

              </v-col>

            </v-row>

            <v-row justify="center">

              <h2>Еще всякие вещи</h2>

            </v-row>

            <v-row justify="center" align-self="center">

              <p>
                 Вот тут можно скачать
                <a @click="downloadExampleCharacter()">
                  пример заполненного листа
                </a>
                . Это мой персонаж-варвар 11го уровня. Может быть, будет кому
                полезно.
              </p>

            </v-row>

          </v-container>

        </v-tab-item>

      </v-tabs-items>

    </v-sheet>

  </main>

</template>

<script>
import AttributeBox from "@/components/AttributeBox";
import SkillBox from "@/components/SkillBox";
import ClassBox from "@/components/ClassBox";
import EncounterBox from "@/components/EncounterBox";
import InventoryBox from "@/components/InventoryBox";
import WeaponBox from "@/components/WeaponBox";
import FeaturesBox from "@/components/FeaturesBox";

import { example } from "@/data/characterExample";
import GenerateSheetModal from "@/components/GenerateSheetModal";
import ErrorsBox from "@/components/ErrorsBox";
import SpellcastingBox from "@/components/SpellcastingBox";
import ImportSheetModal from "./ImportSheetModal";

import { googleFeedbackForm } from "../config";

export default {
  components: {
    ImportSheetModal,
    SpellcastingBox,
    ErrorsBox,
    GenerateSheetModal,
    FeaturesBox,
    WeaponBox,
    InventoryBox,
    EncounterBox,
    ClassBox,
    SkillBox,
    AttributeBox,
  },
  name: "SheetCreator",
  data: () => ({
    googleFeedbackForm: googleFeedbackForm,
    validation: {
      Dex: {
        valid: false,
        errors: [],
      },
      Wis: {
        valid: false,
        errors: [],
      },
      Cha: {
        valid: false,
        errors: [],
      },
      Str: {
        valid: false,
        errors: [],
      },
      Con: {
        valid: false,
        errors: [],
      },
      Int: {
        valid: false,
        errors: [],
      },
      inventory: {
        valid: false,
        errors: [],
      },
    },

    tab: null,
    windowHeight: window.innerHeight - 120,

    characterName: "",
    race: "",
    background: "",
    alignment: "",
    playerName: "",
    xpPoints: null,
    alignmentOptions: [
      "Не используется",
      "Законопослушный добрый",
      "Законопослушный нейтральный",
      "Законопослушный злой",
      "Нейтральный добрый",
      "Истинно нейтральный",
      "Нейтральный злой",
      "Хаотичный добрый",
      "Хаотичный нейтральный",
      "Хаотичный злой",
    ],
    ideals: "",
    bonds: "",
    flaws: "",
    personalityTraits: "",
    proficiencies: {
      language: "",
      armor: "",
      weapon: "",
      tool: "",
    },
    settings: {
      jackOfAllTrades: false,
      skillCheckBonus: 0,
      savingThrowBonus: 0,
      initiativeBonus: 0,
      passivePerceptionAdvantage: false,
      useC2Options: false,
    },

    generationDialog: false,
    importDialog: false,
  }),
  mounted() {
    this.pullData();
  },
  methods: {
    pullData() {
      const characterInfo = this.$store.getters.getInfo;
      const characterPersonality = this.$store.getters.getPersonality;
      const characterProficiencies = this.$store.getters.getProficiencies;
      const characterSettings = this.$store.getters.getSettings;

      // Assign store info
      this.characterName = characterInfo.characterName;
      this.race = characterInfo.race;
      this.background = characterInfo.background;
      this.alignment = characterInfo.alignment;
      this.playerName = characterInfo.playerName;
      this.xpPoints = characterInfo.xpPoints;

      // Assign store personality
      this.ideals = characterPersonality.ideals;
      this.bonds = characterPersonality.bonds;
      this.flaws = characterPersonality.flaws;
      this.personalityTraits = characterPersonality.personalityTraits;

      // Assign store prof
      this.proficiencies = characterProficiencies;

      // Assign store settings
      this.settings = characterSettings;

      // Update versions
      this.$store.commit("checkVersion");
    },
    updateWindowHeight() {
      this.windowHeight = window.innerHeight - 120;
    },
    uploadJson() {
      this.importDialog = true;
    },
    downloadFile(file, type, filename) {
      // `${this.characterName}.json`
      if (!window.confirm("Действительно скачать файл?")) {
        return;
      }

      const blob = new Blob(file, { type });

      this.downloadBlob(blob, filename);
    },
    downloadBlob(blob, filename) {
      const url = URL.createObjectURL(blob);

      let link = document.createElement("a");

      link.href = url;
      link.download = filename;
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    generateList() {
      this.generationDialog = true;
    },
    downloadCharacterJson() {
      this.downloadFile(
        [JSON.stringify(this.$store.getters.getCharacter, null, 2)],
        "application/json",
        `${this.characterName}.json`
      );
    },
    downloadExampleCharacter() {
      this.downloadFile(
        [JSON.stringify(example, null, 2)],
        "application/json",
        "ExampleCharacter.json"
      );
    },
    clearData() {
      window.console.log(this.isValid);
      if (
        window.confirm(
          "Скачайте файл персонажа - без него вы не сможете восстановить текущий лист. " +
            "Данная операция необратима. Вы действительно хотите продолжить?"
        )
      ) {
        this.$store.commit("clearChanges");
        location.reload();
      }
    },
    attributeValidationChanged(value) {
      this.validation[value.attr].valid = value.valid;
      this.validation[value.attr].errors = Array.from(value.errors);
    },
  },
  computed: {
    isValid: function() {
      return Object.values(this.validation).reduce(
        (accumulator, value) => accumulator && value.valid,
        true
      );
    },
    validationErrors: function() {
      return Object.values(this.validation).reduce(
        (accumulator, value) => accumulator.concat(value.errors),
        Array()
      );
    },
  },
  created() {
    window.addEventListener("resize", this.updateWindowHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWindowHeight);
  },
};
</script>

<style scoped>
#scrollable {
  margin-top: 120px;
}

.tab-content {
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  width: 840px;
  margin-left: auto;
  margin-right: auto;
}
</style>
