export const migrations = [migrateFromEmptyVersion, migrateFrom1];

function migrateFromEmptyVersion(state) {
  // Add version tag
  state.version = 1;

  // Add spellcasting attribute
  state.character.spellcasting = [];

  // Add passive perception setting
  state.character.settings.passivePerceptionAdvantage = false;

  return state;
}

function migrateFrom1(state) {
  // Add c2 setting
  state.character.settings.useC2Options = false;

  state.character.skills.Technology = {
    proficiency: 0,
  };

  return state;
}
