export const emptyCharacter = {
  characterInfo: {
    characterName: "",
    background: "",
    playerName: "",
    race: "",
    alignment: "",
    xpPoints: "",
  },
  personality: {
    ideals: "",
    bonds: "",
    flaws: "",
    personalityTraits: "",
  },
  classes: [],
  attributes: {
    Str: {
      score: 10,
      saveProficiency: false,
    },
    Dex: {
      score: 10,
      saveProficiency: false,
    },
    Con: {
      score: 10,
      saveProficiency: false,
    },
    Int: {
      score: 10,
      saveProficiency: false,
    },
    Wis: {
      score: 10,
      saveProficiency: false,
    },
    Cha: {
      score: 10,
      saveProficiency: false,
    },
  },
  skills: {
    Acrobatics: { proficiency: 0 },
    Athletics: { proficiency: 0 },
    Arcana: { proficiency: 0 },
    Deception: { proficiency: 0 },
    History: { proficiency: 0 },
    Insight: { proficiency: 0 },
    Intimidation: { proficiency: 0 },
    Investigation: { proficiency: 0 },
    Medicine: { proficiency: 0 },
    Nature: { proficiency: 0 },
    Perception: { proficiency: 0 },
    Performance: { proficiency: 0 },
    Persuasion: { proficiency: 0 },
    Religion: { proficiency: 0 },
    SleightOfHand: { proficiency: 0 },
    Stealth: { proficiency: 0 },
    Survival: { proficiency: 0 },
    AnimalHandling: { proficiency: 0 },
    Technology: { proficiency: 0 },
  },
  proficiencies: {
    language: "",
    armor: "",
    weapon: "",
    tool: "",
  },
  inventory: {
    gold: 0,
    silver: 0,
    electrum: 0,
    copper: 0,
    platinum: 0,
    backpack: "",
  },
  weapons: [],
  settings: {
    jackOfAllTrades: false,
    skillCheckBonus: 0,
    savingThrowBonus: 0,
    initiativeBonus: 0,
  },
  encounter: {
    speed: 30,
    armorClass: 10,
    hitPoints: 10,
  },
  features: [],
};
