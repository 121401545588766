<template>
  <v-container fill-height>
    <v-row
      v-for="item in playerClasses"
      :key="item.name"
      justify="center"
    >
      <v-card elevation="2" style="width: 100%;" class="classCard">
        <v-list-item three-line>
          <v-list-item-content>
              <v-text-field
                label="Уровень"
                v-model.number="item.level"
                type="number"
                @change="levelChanged(item)"
              ></v-text-field>
            <v-list-item-title class="headline mb-1">
              <v-select
                label="Класс"
                v-model="item.displayedName"
                :items="[item.displayedName].concat(classOptions)"
                :menu-props="{ bottom: true, offsetY: true }"
                @change="displayedNameChanged(item)"
              ></v-select>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar size="100">
            <v-img
              :src="require(`../assets/classIcons/${item.name}.svg`)"
              contain
            />
          </v-list-item-avatar>
        </v-list-item>
          <v-btn
            fab
            small
            dark
            color="pink"
            icon
            @click="deleteClass(item)"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
      </v-card>
    </v-row>
    <v-row justify="center" v-if="classOptions.length > 0">
      <v-btn
        fab
        small
        dark
        color="pink"
        @click="addClassOption()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import {classes} from "@/data/classes"

export default {
  name: "ClassBox",
  data: () => ({
    playerClasses: []
  }),
  mounted() {
    this.playerClasses = this.$store.getters.getClasses;
  },
  methods: {
    displayedNameChanged(item) {
      const index = this.playerClasses.indexOf(item);

      const masterName = Object.keys(classes).filter(x => classes[x].displayedName === item.displayedName)[0];
      item.name = masterName;

      this.$store.commit("changeClass", {
        classIndex: index,
        displayedName: item.displayedName,
        name: masterName,
        level: item.level,
      })
    },
    levelChanged(item) {
      const index = this.playerClasses.indexOf(item);
      const masterName = Object.keys(classes).filter(x => classes[x].displayedName === item.displayedName)[0];

      this.$store.commit("changeClass", {
        classIndex: index,
        displayedName: item.displayedName,
        name: masterName,
        level: item.level,
      })
    },
    addClassOption() {
      const unusedClass = this.classOptions[0];
      const masterName = Object.keys(classes).filter(x => classes[x].displayedName === unusedClass)[0];

      const newClass = {
        displayedName: unusedClass,
        name: masterName,
        level: 1
      }

      this.playerClasses.push(newClass);
      this.$store.commit("addClass", newClass);
    },
    deleteClass(item) {
      const index = this.playerClasses.indexOf(item);
      this.playerClasses.splice(index, 1);

      this.$store.commit("removeClass", index)
    }
  },
  computed: {
    classOptions: function () {
      const classList = Object.keys(classes).map(item => classes[item].displayedName);
      const usedClasses = this.playerClasses.map(item => item.displayedName);

      return classList.filter(item => usedClasses.indexOf(item) === -1)
    }
  }
}
</script>

<style scoped>
  .classCard {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>